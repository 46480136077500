.vjs-resolution-button {
  color: #FFFFFF;
  font-family: VideoJS;
  width: 3.7em !important;
  opacity: 0.8;
}

.vjs-resolution-button:hover {
  opacity: 1;
}

.vjs-resolution-button>.vjs-menu-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.vjs-resolution-button .vjs-resolution-button-staticlabel {
  pointer-events: none;
  font-size: 1.5em;
  line-height: 3.5;
  text-align: center;
}

/* .vjs-resolution-button .vjs-resolution-button-staticlabel:before {
  content: '\f110';
  font-size: 1.8em;
  line-height: 1.67;
} */

.vjs-workinghover .vjs-menu-button-popup:hover .vjs-menu {
  display: none;
}

.vjs-resolution-button .vjs-menu .vjs-selected {
  background-color: #fff !important;
  color: #2B333F !important;
}

.vjs-resolution-button .vjs-resolution-button-label {
  pointer-events: none;
  font-size: 1em;
  font-weight: 600;
  line-height: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  box-sizing: inherit;
  font-family: Arial, Helvetica, sans-serif;
}

.vjs-resolution-button ul.vjs-menu-content {
  width: 4em !important;
}

.vjs-resolution-button .vjs-menu {
  left: -4px;
  bottom: 0;
}

.vjs-resolution-button .vjs-menu li {
  text-transform: none;
  font-size: 1em;
  font-family: Arial, Helvetica, sans-serif;
}