a.btn.disabled {
  cursor: not-allowed !important;
}

.mtm-button {
  border-radius: 0.25rem;
  padding: 0.25rem 1rem;
  text-decoration: none;
}

.mtm-button:hover {
  opacity: 0.8;
  text-decoration: none;
}

.mtm-button.mtm-main-button {
  color: #ffffff;
  background-color: #ff5900;
}

.mtm-button.mtm-main-button:hover {
  color: #ffffff;
  background-color: rgba(255, 89, 0, 0.9) !important;
}

.mtm-button.mtm-white-button {
  background-color: #ffffff;
  border: 1px solid #D4D4DE;
}

.mtm-button.mtm-border-white-button {
  border: 2px solid #ffffff;
}

.mtm-button.mtm-border-orange-button {
  border: 2px solid #ff5900;
}

.mtm-button.mtm-border-button {
  border: 1px solid #000;
}

.mtm-button.mtm-grey-button {
  background-color: #CDD3E4;
}

.mtm-button.mtm-black-button {
  background-color: #18182D;
}