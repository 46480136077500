.mtm-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mtm-icon-copy-paste {
  background-image: url("/assets/img/icons/review-link/paste-icon.svg");
}

.mtm-icon-info {
  background-image: url("/assets/img/icons/review-link/information.svg");
}

.mtm-icon-share-link {
  background-image: url("/assets/img/icons/review-link/copy-link-red-dark.svg");
}

.mtm-icon-share-link-red {
  background-image: url("/assets/img/icons/review-link/copy-link-red.svg");
}

.mtm-icon-eye-cross {
  background-image: url("/assets/img/eye-cross.svg");
}

.mtm-icon-eye {
  background-image: url("/assets/img/eye.svg");
}

.mtm-icon-side-by-side {
  background-image: url("/assets/img/icons/side-by-side.svg");
}

.mtm-icon-above-and-below {
  background-image: url("/assets/img/icons/above-and-below.svg");
}

.mtm-icon-archive {
  background-image: url("/assets/img/archive-icon.svg");
}

.mtm-icon-back {
  background-image: url("/assets/img/back_icon.svg");
}

.mtm-icon-draw {
  background-image: url("/assets/img/icons/draw.svg");
}

.mtm-icon-align-left {
  background-image: url("/assets/img/icons/align-left.svg");
}

.mtm-icon-align-left.active,
.mtm-icon-align-left:hover {
  background-image: url("/assets/img/icons/align-left-hover.svg");
}

.mtm-icon-align-right {
  background-image: url("/assets/img/icons/align-right.svg");
}

.mtm-icon-align-right.active,
.mtm-icon-align-right:hover {
  background-image: url("/assets/img/icons/align-right-hover.svg");
}

.mtm-icon-align-center {
  background-image: url("/assets/img/icons/align-center.svg");
}

.mtm-icon-align-center.active,
.mtm-icon-align-center:hover {
  background-image: url("/assets/img/icons/align-center-hover.svg");
}

.mtm-icon-align-top {
  background-image: url("/assets/img/icons/align-top.svg");
}

.mtm-icon-align-top.active,
.mtm-icon-align-top:hover {
  background-image: url("/assets/img/icons/align-top-hover.svg");
}

.mtm-icon-align-bottom {
  background-image: url("/assets/img/icons/align-bottom.svg");
}

.mtm-icon-align-bottom.active,
.mtm-icon-align-bottom:hover {
  background-image: url("/assets/img/icons/align-bottom-hover.svg");
}

.mtm-icon-folder-open {
  background-image: url("/assets/img/icons/folder-open.svg");
}

.mtm-icon-folder-closed {
  background-image: url("/assets/img/icons/folder-closed.svg");
}

.mtm-icon-add-conversation {
  background-image: url("/assets/img/icons/add-conversation-icon.svg");
}

.mtm-icon-pin {
  background-image: url("/assets/img/icons/pin.svg");
}

.mtm-icon-plane {
  background-image: url("/assets/img/icons/plane.svg");
}

.mtm-icon-smile {
  background-image: url("/assets/img/icons/smile.svg");
}

.mtm-icon-video-chat {
  background-image: url("/assets/img/icons/video-chat.svg");
}
.mtm-icon-task-icon {
  background-image: url("/assets/img/icons/task-icon.svg");
}
.mtm-icon-task-icon-black {
  background-image: url("/assets/img/icons/task-icon-black.svg");
}
.mtm-icon-crown-red {
  background-image: url("/assets/img/icons/crown-red.svg");
}
.mtm-icon-task-priority-highest {
  background-image: url("/assets/img/icons/task-priority-highest.svg");
}
.mtm-icon-task-priority-high {
  background-image: url("/assets/img/icons/task-priority-high.svg");
}
.mtm-icon-task-priority-medium {
  background-image: url("/assets/img/icons/task-priority-medium.svg");
}
.mtm-icon-task-priority-low {
  background-image: url("/assets/img/icons/task-priority-low.svg");
}
.mtm-icon-task-priority-lowest {
  background-image: url("/assets/img/icons/task-priority-lowest.svg");
}
.mtm-icon-calendar-black {
  background-image: url("/assets/img/icons/calendar-black.svg");
}
.mtm-icon-add {
  background-image: url("/assets/img/icons/add-icon.svg");
}
.mtm-icon-union-purple {
  background-image: url("/assets/img/icons/union-purple.svg");
}
.mtm-icon-union-green {
  background-image: url("/assets/img/icons/union-green.svg");
}
.mtm-icon-union-blue {
  background-image: url("/assets/img/icons/union-blue.svg");
}
.mtm-icon-union-gray {
  background-image: url("/assets/img/icons/union-gray.svg");
}
.mtm-icon-union-yellow {
  background-image: url("/assets/img/icons/union-yellow.svg");
}
.mtm-icon-union-orange {
  background-image: url("/assets/img/icons/union-orange.svg");
}
.mtm-icon-union-white {
  background-image: url("/assets/img/icons/union-white.svg");
}
.mtm-icon-camera-black {
  background-image: url("/assets/img/icons/camera-black.svg");
}
.mtm-icon-share-icon {
  background-image: url("/assets/img/icons/share-icon.svg");
}
.mtm-icon-edit-pencil-circle {
  background-image: url("/assets/img/icons/edit-pencil-circle.svg");
}
.mtm-icon-upload-icon-circle {
  background-image: url("/assets/img/icons/upload-icon-circle.svg");
}
.mtm-icon-section-tree {
  background-image: url("/assets/img/icons/section-icon-tree.svg");
}
.mtm-icon-sub-section-tree {
  background-image: url("/assets/img/icons/sub-section-icon-tree.svg");
}
.mtm-icon-topic-tree {
  background-image: url("/assets/img/icons/topic-icon-tree.svg");
}
.mtm-icon-option-tree {
  background-image: url("/assets/img/icons/option-icon-tree.svg");
}
.mtm-icon-version-tree {
  background-image: url("/assets/img/icons/version-icon-tree.svg");
}
.mtm-icon-asset-tree {
  background-image: url("/assets/img/icons/version-asset-tree.svg");
}
.mtm-icon-polygon-left {
  background-image: url("/assets/img/polygon-left.svg");
}
.mtm-icon-polygon-right {
  background-image: url("/assets/img/polygon-right.svg");
}
.mtm-icon-add-user {
  background-image: url("/assets/img/icons/add-user.svg");
}
.mtm-icon-add-group {
  background-image: url("/assets/img/icons/add-group.svg");
}
.mtm-icon-import-team {  
  background-image: url("/assets/img/icons/import-team.svg");
}
  .mtm-icon-subtitle-file {
  background-image: url("/assets/img/icons/subtitle-file.svg");
}
.mtm-icon-download-green {
  background-image: url("/assets/img/icons/download-green.svg");
}
