.video-js.vjs-vjsdownload .vjs-button.vjs-vjsdownload {
	cursor: pointer;
	display: block;
	font-family: VideoJs;
}

.video-js.vjs-vjsdownload .vjs-button.vjs-vjsdownload {
	opacity: 0.8;
}

.video-js.vjs-vjsdownload .vjs-button.vjs-vjsdownload:hover {
	opacity: 1;
}

.video-js.vjs-vjsdownload .vjs-button.vjs-vjsdownload:before {
	display: block;
	position: relative;
	top: 4px;
	content: "\f101";
	transform: rotate(90deg);
	font-size: 1.5em;
}

.video-js.vjs-vjsdownload .vjs-button.vjs-vjsdownload:after {
	display: block;
	content: " ";
	width: 4px;
	height: 5px;
	background-color: white;
	position: relative;
	top: -11px;
	left: calc(50% - 2px);
}

.video-js.vjs-vjsdownload .vjs-button.vjs-vjsdownload:after,
.video-js.vjs-vjsdownload .vjs-button.vjs-vjsdownload:before {
	display: none;
}

.video-js.vjs-vjsdownload .vjs-button.vjs-vjsdownload.vjs-menu-open .vjs-download-menu {
	display: block !important;
}

.video-js.vjs-vjsdownload .vjs-button.vjs-vjsdownload {
	background: url(../img/icons/download/dwd_white.png) no-repeat center;
	background-size: contain;
}

.video-js.vjs-vjsdownload .vjs-button.vjs-vjsdownload.downloading {
	background: url(../img/icons/download/cloud_only_white.png) no-repeat center !important;
	background-size: contain !important;
}

@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.video-js.vjs-vjsdownload .vjs-button.vjs-vjsdownload {
	z-index: 9;
	font-family: "Montserrat", sans-serif !important;
}

.video-js.vjs-vjsdownload .vjs-button.vjs-vjsdownload.downloading:after {
	-webkit-animation: spin 1s linear infinite;
	-moz-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
	background: url(../img/icons/download/green_circle.png) no-repeat center !important;
	background-size: contain !important;
	display: block;
	height: 33% !important;
	width: 50% !important;
	top: 8px !important;
	left: 8px !important;
}

.video-js.vjs-vjsdownload .vjs-download-menu {
	list-style: none;
	position: absolute;
}

.vjs-vjsdownload .vjs-download-menu {
	display: none;
	font-size: 14px;
	transform: translate(-35%, calc(-100% - 14px));
}

.vjs-vjsdownload.vjs-control.vjs-button .vjs-download-menu {
	background-color: rgba(43, 51, 63, 0.7);
}

.video-js.vjs-vjsdownload .vjs-download-menu li {
	color: #ccc;
	cursor: default;
	display: block;
	font-family: Arial, Helvetica, sans-serif;
	//font-weight: 600;
	line-height: 1.5;
	padding: 0.25rem 0.75rem;
	text-align: left;
	text-shadow: none !important;
	white-space: nowrap;
	opacity: 0.8;
}

.video-js.vjs-vjsdownload .vjs-download-menu li.downloadable-file {
	opacity: 1;
	cursor: pointer;
	font-weight: 600;
	font-size: 13px;
	text-transform: capitalize;
}

.video-js.vjs-vjsdownload .vjs-download-menu li.downloadable-file:hover {
	background-color: rgba(255, 255, 255, 0.1);
}