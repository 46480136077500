quill-editor .ql-emojiblot .ap,
quill-editor #emoji-palette #tab-panel .ap {
	margin-top: 0;
}

quill-editor .quill-mention-list {
	max-height: 320px;
	overflow: auto;
}

.mention {
	background-color: rgba(232, 66, 6, 0.1) !important;
	color: #ff5900 !important;
	font-size: 14px !important;
}