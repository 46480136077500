/** color **/
.c-mtm-brand {
  color: #FF5900 !important;
}

.c-full-white {
  color: #ffffff !important;
}

.c-mtm-black {
  color: #1C202C !important;
}

.c-mtm-red {
  color: #FF4A6D !important;
}

.c-mtm-purple {
  color: #69699D !important;
}

.c-mtm-green {
  color: #6DC58F !important;
}

.c-mtm-dark-purple {
  color: #2E2E53 !important;
}

.c-mtm-orange {
  color: #FF5900 !important;
}

.c-mtm-full-black {
  color: #000000 !important;
}

.c-mtm-purple {
  border-color: #69699D !important;
}

.b-mtm-brand {
  background-color: #FF5900 !important;
}

.b-mtm-brand-secondary {
  background-color: #FD5021 !important;
}

.b-mtm-red-light {
  background-color: #EB4545 !important;
}

.b-mtm-full-white {
  background-color: #ffffff !important;
}

.b-mtm-red {
  background-color: #FF4A6D !important;
}

.b-mtm-black {
  background-color: #1C202C !important;
}

.b-mtm-green {
  background-color: #6DC58F !important;
}

.b-mtm-grey {
  background-color: #ECEEF4 !important;
}

.b-purple-default {
  background-color: #E0E6FA !important;
}

.br-brand {
  border-color: #FF5900 !important;
}

.br-white {
  border-color: #ffffff !important;
}

.br-purple {
  border-color: #69699D !important;
}