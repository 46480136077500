.video-js .vjs-menu-button-inline.vjs-slider-active,
.video-js .vjs-menu-button-inline:focus,
.video-js .vjs-menu-button-inline:hover,
.video-js.vjs-no-flex .vjs-menu-button-inline {
	width: 10em;
}

.video-js .vjs-controls-disabled .vjs-big-play-button {
	display: none !important;
}

.video-js .vjs-control {
	width: 3em;
}

.video-js .vjs-control.vjs-time-control {
	width: auto;
}

.video-js .vjs-control.vjs-vjsdownload {
	width: 1.8rem;
	margin-right: 4px;
	margin-left: 4px;
}

.video-js.vjs-vjsdownload .vjs-button.vjs-vjsdownload:after {
	top: -16px !important;
	height: 8px !important;
}

.video-js .vjs-menu-button-inline:before {
	width: 1.5em;
}

.vjs-menu-button-inline .vjs-menu {
	left: 3em;
}

.vjs-paused.vjs-has-started.video-js .vjs-big-play-button,
.video-js.vjs-ended .vjs-big-play-button,
.video-js.vjs-paused .vjs-big-play-button {
	display: block;
}

.video-js .vjs-load-progress div,
.vjs-seeking .vjs-big-play-button,
.vjs-waiting .vjs-big-play-button {
	display: none !important;
}

.video-js .vjs-mouse-display:after,
.video-js .vjs-play-progress:after {
	padding: 0 0.4em 0.3em;
}

.video-js.vjs-ended .vjs-loading-spinner {
	display: none;
}

.video-js.vjs-ended .vjs-big-play-button {
	display: block !important;
}

video-js.vjs-ended .vjs-big-play-button,
.video-js.vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started.video-js .vjs-big-play-button {
	display: block;
}

.video-js .vjs-big-play-button {
	top: 50%;
	left: 50%;
	margin-left: -1.5em;
	margin-top: -1em;
}

.video-js .vjs-big-play-button {
	background-color: rgba(228, 68, 42, 0.91);
	font-size: 2.5em;
	border-radius: 3%;
	height: 1.7em !important;
	line-height: 1.7em !important;
	margin-top: -0.85em !important;
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus,
.video-js .vjs-big-play-button:active {
	background-color: #e4442a;
}

.video-js .vjs-loading-spinner {
	border-color: #e4442a;
}

.video-js .vjs-control-bar2 {
	background-color: #000000;
}

.video-js .vjs-control-bar {
	opacity: 1 !important;
	background-color: transparent !important;
	color: #ffffff;
	font-size: 14px;
}

.video-js .vjs-play-progress,
.video-js .vjs-volume-level {
	background-color: #ffffff;
}

.video-js .vjs-slider {
	background-color: #3b3d41;
}

.video-js .vjs-load-progress {
	background: rgba(255, 255, 255, 0.4);
}

.video-js .vjs-big-play-button {
	border-color: transparent;
}

.video-js * {
	outline: none !important;
}