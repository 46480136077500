.mtm-checkbox {
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mtm-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.mtm-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid #eee;
}

.mtm-checkbox:hover input~.checkmark {
  background-color: rgba(255, 255, 255, 0.2);
}

.mtm-checkbox input:checked~.checkmark {
  background-color: white;
}

.mtm-checkbox.double-tick .checkmark:before,
.mtm-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.mtm-checkbox.double-tick input:checked~.checkmark:before,
.mtm-checkbox input:checked~.checkmark:after {
  display: block;
}

.mtm-checkbox.double-tick .checkmark:before,
.mtm-checkbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mtm-checkbox.double-tick .checkmark:before,
.mtm-checkbox.double-tick .checkmark:after {
  width: 5px;
  height: 9px;
}

.mtm-checkbox.double-tick .checkmark:after {
  top: 5px;
  left: 7px;
}

.mtm-checkbox.double-tick .checkmark:before {
  top: 1px;
  left: 7px;
}

.mtm-checkbox.double-tick.mtm-checkbox-green .checkmark:before,
.mtm-checkbox.mtm-checkbox-green .checkmark:after {
  border-color: #4FC18E;
}

.mtm-checkbox.double-tick.mtm-checkbox-red .checkmark:before,
.mtm-checkbox.mtm-checkbox-red .checkmark:after {
  border-color: #E94913;
}

.comparer-container .drawing-hidden.video-player .video-js.vjs-fluid {
  padding-top: 24rem !important;
}

.comparer-container .slider-opacity .ng5-slider.animate .ng5-slider-pointer {
  transition: none;
}

.comparer-container .slider-opacity .ng5-slider-limit,
.comparer-container .slider-opacity .ng5-slider-model-value {
  display: none;
}

.comparer-container .slider-opacity .ng5-slider .ng5-slider-pointer {
  background-color: #ffffff;
  top: -10px;
  width: 24px;
  height: 24px;
}

.comparer-container .slider-opacity .ng5-slider .ng5-slider-pointer::after {
  background-color: #E94913;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 8px;
}

mtm-version-comparer .above-and-below mtm-media-player .media-player-container {
  pointer-events: none !important;
}

mtm-version-comparer .above-and-below mtm-media-player .media-player-container .vjs-big-play-button {
  display: none !important;
}

.timer-caption-editor .vjs-fullscreen .vjs-text-track-display .vjs-text-track-cue>div {
  font-size: 101% !important;
}

.media-player-preview.modal .modal-dialog {
  width: 780px;
}