.conversation-container simple-chat-popup .chat-messages {
  background-color: #ffffff;
}

.conversation-container simple-chat-popup .chat-messages .chat-input-message {
  border-top: 1px solid #e2e6ec !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
}

.conversation-container simple-chat-popup .text-secondar {
  color: red;
}

.conversation-container simple-chat-popup .emoji-mart {
  right: auto !important;
  top: auto !important;
  bottom: 44px;
}

.conversation-container simple-chat-popup .emoji-mart .emoji-mart-scroll {
  height: 172px !important;
}

.conversation-container mtm-group-chat-picture .group-container {
  border: 2px solid #EEF2F9;
}

.conversation-container mtm-user-profile-picture .icon-image,
.conversation-container mtm-user-profile-picture .icon-color {
  border: 2px solid #EEF2F9;
}

key-file .loader-container .text-secondar {
  color: red;
}

mtm-rightbar .list-group {
  border-radius: 0 !important;
}

.dashboard-container .task-info-detail {
  max-width: 218px !important;
  min-width: 218px !important;
}