body .video-container.portfolio-video-container .video-js.vjs-fullscreen .vjs-control.vjs-button.vjs-ellipsis-icon {
	display: none !important;
}

.vjs-control.vjs-button.vjs-ellipsis-icon {
	position: absolute;
	right: 0px;
	text-align: right;
	top: -2px;
	width: auto;
}

.vjs-control.vjs-button.vjs-ellipsis-icon:before {
	content: '\22EE';
	font-size: 1.25rem;
	font-weight: bold;
}

.vjs-control-bar.three-dot-menu-hovered:not(.vjs-fullscreen) .vjs-fullscreen-control.vjs-control.vjs-button,
.vjs-control-bar.three-dot-menu-hovered:not(.vjs-fullscreen) .vjs-duration.vjs-time-control.vjs-control,
.vjs-control-bar.three-dot-menu-hovered:not(.vjs-fullscreen) .vjs-time-control.vjs-time-divider,
.vjs-control-bar.three-dot-menu-hovered:not(.vjs-fullscreen) .vjs-current-time.vjs-time-control.vjs-control,
.vjs-control-bar.three-dot-menu-hovered:not(.vjs-fullscreen) .vjs-volume-panel.vjs-control.vjs-volume-panel-horizontal,
.vjs-control-bar.three-dot-menu-hovered:not(.vjs-fullscreen) .vjs-play-control.vjs-control.vjs-button {
	pointer-events: none;
	opacity: 0;
}


body .video-container.portfolio-video-container .video-js:not(.vjs-fullscreen) .vjs-control-bar.three-dot-menu-hovered:not(.vjs-fullscreen) .vjs-playback-rate.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button {
	height: 24px;
	width: 2rem !important;
	top: 6px;
	left: -100px !important;
}

body .video-container.portfolio-video-container .video-js:not(.vjs-fullscreen) .vjs-control-bar.three-dot-menu-hovered:not(.vjs-fullscreen) .vjs-playback-rate.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button .vjs-menu {
	display: none !important;
}

body .video-container.portfolio-video-container .video-js:not(.vjs-fullscreen) .vjs-control-bar.three-dot-menu-hovered:not(.vjs-fullscreen) .vjs-playback-rate.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button .vjs-playback-rate-value {
	top: 0;
}

body .video-container.portfolio-video-container .video-js:not(.vjs-fullscreen) .vjs-control-bar.three-dot-menu-hovered:not(.vjs-fullscreen) .vjs-vjsdownload.vjs-control.vjs-button {
	top: 9px !important;
	right: -20px;
}

body .video-container.portfolio-video-container .video-js:not(.vjs-fullscreen) .vjs-control-bar.three-dot-menu-hovered:not(.vjs-fullscreen) .vjs-picture-in-picture-control.vjs-control.vjs-button {
	right: 1rem !important;
	top: 6px;
}

body .video-container.portfolio-video-container .video-js:not(.vjs-fullscreen) .vjs-control-bar.three-dot-menu-hovered:not(.vjs-fullscreen) .vjs-picture-in-picture-control.vjs-control.vjs-button .vjs-icon-placeholder:before {
	top: -7px;
}

body .video-container.portfolio-video-container .video-js:not(.vjs-fullscreen) .vjs-playback-rate.vjs-hover {
	border-color: transparent !important;
}


body .video-container.portfolio-video-container .video-js:not(.vjs-fullscreen) .vjs-control-bar.three-dot-menu-hovered:not(.vjs-fullscreen) .vjs-playback-rate.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button,
body .video-container.portfolio-video-container .video-js:not(.vjs-fullscreen) .vjs-control-bar.three-dot-menu-hovered:not(.vjs-fullscreen) .vjs-vjsdownload.vjs-control.vjs-button,
body .video-container.portfolio-video-container .video-js:not(.vjs-fullscreen) .vjs-control-bar.three-dot-menu-hovered:not(.vjs-fullscreen) .vjs-picture-in-picture-control.vjs-control.vjs-button {
	display: block !important;
	height: 20px;
}