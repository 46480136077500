.task-dropdown-menu .task-dropdown-item {
  cursor: pointer;
  font-size: 12px;
  padding: 6px 8px;
}
.task-dropdown-menu .task-dropdown-item.dropdown-item:active {
  background-color: #e9ecef;
}

mtm-create-task .description-editor-nav {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
mtm-create-task .description-editor-nav mtm-text-editor .mtm-text-editor {
  flex-direction: column;
  flex-flow: column-reverse;
}
mtm-create-task .description-editor-nav mtm-text-editor .mtm-text-editor emoji-mart {
  bottom: 22px;
  right: 0;
  left: auto;
  top: auto;
}

.task-main-page-component .single-task-content .task-info-detail {
  max-width: auto;
  min-width: auto;
}

mtm-create-task .description-editor-nav mtm-text-editor .mtm-text-editor .mtm-icon-pin {
  right: 4px;
  bottom: 5px;
}

mtm-create-task .description-editor-nav mtm-text-editor .mtm-text-editor .mtm-icon-smile {
  right: 20px;
  bottom: 5px;
}

mtm-create-task .description-editor-nav mtm-text-editor .mtm-text-editor .mtm-icon-pin,
mtm-create-task .description-editor-nav mtm-text-editor .mtm-text-editor .mtm-icon-smile {
  position: absolute !important;
  right: 0 !important;
  bottom: 4px !important;
}

mtm-create-task .description-editor-nav mtm-text-editor .mtm-text-editor .mtm-icon-pin::after
mtm-create-task .description-editor-nav mtm-text-editor .mtm-text-editor .mtm-icon-smile::after {
  content: "";
  width: 100%;
  background-color: #e2e6ec;
  height: 16px;
}
mtm-create-task .description-editor-nav mtm-text-editor .mtm-text-editor.expandable .mtm-text-editor-element {
  width: 100%;
  font-size: 13px;
  padding: 6px 8px !important;
  height: auto !important;
  min-height: 53px !important;
}

mtm-create-task .task-related-to-dropdown .mtm-assets-dropdown .items-list {
  border-radius: 10px 10px 0 0 !important;
  border-top: 1px solid #e2e6ec !important;
  border-bottom: none !important;
  transform: translateY(calc(-100% - 32px));
  top: auto !important;
}

mtm-create-task .task-related-to-dropdown .mtm-assets-dropdown .mtm-assets-item.root.open {
  border-radius: 0 0 10px 10px !important;
}

mtm-create-task mtm-text-editor emoji-mart.emoji-mart {
  display: block !important;
}

//for workspace mtm-create-task-w2 component
mtm-create-task-w2 .description-editor-nav {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
mtm-create-task-w2 .description-editor-nav mtm-text-editor .mtm-text-editor {
  flex-direction: column;
  flex-flow: column-reverse;
}
mtm-create-task-w2 .description-editor-nav mtm-text-editor .mtm-text-editor emoji-mart {
  bottom: 22px;
  right: 0;
  left: auto;
  top: auto;
}

.task-main-page-component .single-task-content .task-info-detail {
  max-width: auto;
  min-width: auto;
}

mtm-create-task-w2 .description-editor-nav mtm-text-editor .mtm-text-editor .mtm-icon-pin {
  right: 4px;
  bottom: 5px;
}

mtm-create-task-w2 .description-editor-nav mtm-text-editor .mtm-text-editor .mtm-icon-smile {
  right: 20px;
  bottom: 5px;
}

mtm-create-task-w2 .description-editor-nav mtm-text-editor .mtm-text-editor .mtm-icon-pin,
mtm-create-task-w2 .description-editor-nav mtm-text-editor .mtm-text-editor .mtm-icon-smile {
  position: absolute !important;
  right: 0 !important;
  bottom: 4px !important;
}

mtm-create-task-w2 .description-editor-nav mtm-text-editor .mtm-text-editor .mtm-icon-pin::after
mtm-create-task-w2 .description-editor-nav mtm-text-editor .mtm-text-editor .mtm-icon-smile::after {
  content: "";
  width: 100%;
  background-color: #e2e6ec;
  height: 16px;
}
mtm-create-task-w2 .description-editor-nav mtm-text-editor .mtm-text-editor.expandable .mtm-text-editor-element {
  width: 100%;
  font-size: 13px;
  padding: 6px 8px !important;
  height: auto !important;
  min-height: 53px !important;
}

mtm-create-task-w2 .task-related-to-dropdown .mtm-assets-dropdown .items-list {
  border-radius: 10px 10px 0 0 !important;
  border-top: 1px solid #e2e6ec !important;
  border-bottom: none !important;
  transform: translateY(calc(-100% - 32px));
  top: auto !important;
}

mtm-create-task-w2 .task-related-to-dropdown .mtm-assets-dropdown .mtm-assets-item.root.open {
  border-radius: 0 0 10px 10px !important;
}

mtm-create-task-w2 mtm-text-editor emoji-mart.emoji-mart {
  display: block !important;
}