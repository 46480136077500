.vjs-control-bar .subtitle-menu-container {
	display: none;
	position: absolute;
	bottom: 2.6125rem;
	left: 50%;
	transform: translateX(-50%);
	width: 320px;
	background-color: rgba(43, 51, 63, 0.7);
	color: #ccc;
	padding: 12px 10px;
	border-radius: 2px;
}

.vjs-control-bar .subtitle-menu-container * {
	text-shadow: none !important;
}

.vjs-control-bar.subtitle-menu-open .subtitle-menu-container {
	display: block;
}

.vjs-control-bar .subtitle-menu-container .subtitle-label {
	text-align: left;
	font-size: 14px;
	margin-bottom: 14px;
}

.subtitle-menu-container .subtitle-menu-item {
	display: flex;
	align-content: center;
}

.subtitle-menu-container .subtitle-menu-item .file-name-label {
	margin-right: auto;
}

.subtitle-menu-container .subtitle-menu-item .file-download {
	cursor: pointer;
	margin-left: auto;
}

.subtitle-menu-container .subtitle-menu-item .file-download:hover {
	opacity: 0.8;
}

.subtitle-menu-container .subtitle-menu-item {
	display: flex;
	align-items: center;
}

.subtitle-menu-container .vjs-subtitle-toggle {
	margin-top: -10px;
	margin-right: 8px;
}

.subtitle-menu-container .file-name-label {
	flex: 1;
	text-align: left;
}