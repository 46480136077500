.manage-storage-modal .modal-dialog,
.transfer-ownership-modal .modal-dialog,
.delete-files-modal .modal-dialog,
.add-or-remove-storage-modal .modal-dialog {
  max-width: 800px;
}

.add-new-company-user-modal .modal-dialog {
  max-width: 500px !important;
}

.add-new-company-user-modal .modal-dialog .modal-body {
  min-width: 500px !important;
}