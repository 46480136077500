.team-data-list-container .folder-table-row {
  box-shadow: none !important;
}

.team-data-list-container.rightbar-blocks-no-collapsed .leftbadge .file-info .nameFileFolder {
  font-size: 14px !important;
  margin-left: 6px !important;
  margin-bottom: 22px !important;
}

.team-data-list-container.rightbar-blocks-no-collapsed .leftbadge .small-list-view .file-info {
  padding-right: 0 !important;
}

.team-data-list-container.rightbar-blocks-no-collapsed .rightbadge {
  min-width: 0 !important;
  flex-basis: 0 !important;
}

.team-data-list-container.rightbar-blocks-no-collapsed .rightbadge .badgebtn-container {
  right: unset !important;
  left: 45px;
  bottom: 10px !important;
}

.team-data-list-container.rightbar-blocks-no-collapsed .rightbadge li.mt-1 {
  margin-top: 0 !important;
  font-size: 0.625rem !important;
  width: 1.25rem !important;
  height: 18px;
}