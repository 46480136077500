.video-js {
	margin: 30px auto;
}

#test-control-panel {
	margin: 50px auto;
	width: 50%;
	height: auto;
	background-color: #fff;
	border: 2px solid #0097b1;
	display: flex;
	flex-wrap: wrap;
}

.control-section {
	width: 26%;
	border: 1px #0097b1 solid;
	padding: 2%;
	display: flex;
	flex-direction: column;
	margin: 5px;
}

.toolbarPallete {
	text-align: center;
	position: relative;
}

.hidden {
	display: none;
}

.toolbarOptions {
	cursor: pointer;
	padding: 8px 6px;
}

.toolbarOptions.separator {
	/* background-color: rgba(255, 255, 255, 0.6);
	height: 32px;
	margin: 0 2px;
	padding: 0;
	position: relative;
	top: 1px;
	width: 2px;
	vertical-align: top; */
	width: 0;
	height: 0;
	padding: 0;
}

.toolbarOptionsColors {
	display: inline-block;
	border: 3px solid #24272E;
	border-radius: 50%;
	padding: 15px 15px;
	border-color: black;
}

.annotation-color-picker {
	background-color: #1d1d1d;
	border-radius: 4px;
	left: 0px;
	/* position: absolute; */
	top: 65px;
	line-height: 1;
	display: block !important;
}

.annotation-color-picker>div {
	cursor: pointer;
	width: 24px;
	display: inline-block;
	height: 24px;
	margin: 5px;
	border-radius: 1rem;
	opacity: 0.8;
}

.annotation-color-picker>div.active {
	opacity: 1;
	border: 2px solid #18182D;
	outline: 2px solid #CCC;
}

.picker-wrapper {
	padding: 20px;

}

.annotation-color-picker>div:hover {
	opacity: 1;
	border: 2px solid #18182D;
	outline: 2px solid #CCC;
}

.selected-color {
	background-color: #ED0990;
	height: 24px;
	width: 24px;
	border-radius: 0.325rem;
}

.vjs-control.vjs-button.vac-player-btn,
.vac-control,
.vac-controls,
.vac-comments-container {
	display: none !important;
}

.video-js.vjs-picture-in-picture .vac-canvas {
	display: none !important;
}