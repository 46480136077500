.w-18 {
  width: 18px;
}

.h-18 {
  height: 18px;
}

.w-24 {
  width: 24px;
}

.h-24 {
  height: 24px;
}

.w-28 {
  width: 28px;
}

.h-28 {
  height: 28px;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}