.t-uppercase {
  text-transform: uppercase;
}

._bold {
  font-weight: bold !important;
}

._600 {
  font-weight: 600 !important;
}

._700 {
  font-weight: 700 !important;
}

._800 {
  font-weight: 800 !important;
}

.b-r-4 {
  border-radius: 4px;
}