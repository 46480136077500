.pointer {
  cursor: pointer;
}

.text {
  cursor: text;
}

.rounded-modal .modal-content {
  border-radius: 15px;
}